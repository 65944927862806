"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyPropsDiff = exports.getPropsDiff = exports.deletionSentinel = void 0;
const json_1 = require("./json");
exports.deletionSentinel = '__DELETE_THIS_PROP__';
/**
 * Creates a diff object that can be used to apply overrides to grouped module's props.
 * Typically, the Layout editor will present the group default props and then this function
 * is used to describe any changes the author makes to that specific instance.
 * Note: The way that diffs are created in the Layout Editor, there is no way to affirm a
 * specific value that is present in the default props should forever stay the same. Eg, if the
 * default value is "A", one cannot force a value of "A" in the diff to guard against future
 * changes to the default, though data-wise it is possible.
 * Note: JSON.stringify and JSON.parse are used liberally here because they're known to be very
 * fast, their usage is terse, and we're not dealing with large objects.
 * @param base JSONValue that can be overridden.
 * @param altered The output of the props form, with the result of any changes made
 * by the content editor for a given grouped (staff or boss) module.
 * @param depth How many levels of recursion this invocation is operating at. This is
 * likely exclusively relevant only for depth === 0.
 * @returns A diff object that can be used to apply overrides to grouped module's props.
 */
const getPropsDiff = (base, altered, depth = 0) => {
    // No base but all override means `altered` is the entire output
    if (depth === 0 &&
        altered &&
        JSON.stringify(altered) !== '{}' &&
        (!base || JSON.stringify(base) === '{}'))
        return JSON.parse(JSON.stringify(altered));
    // No overrides means an empty diff
    if (depth === 0 && (!altered || JSON.stringify(altered) === '{}') && base) {
        return {};
    }
    // Because there are different, incompatible goals for creating and applying diffs
    // to arrays, we sidestep the question by returning the whole array here. This can
    // happen under two circumstances:
    // 1. The whole object (base at depth 0, no chance to recurse) is an array, or
    // 2. This is 1+ levels of recursion in, and the arrays were not JSON-equivalent, so
    //    the edited version will supplant the base version.
    if (Array.isArray(altered))
        return JSON.parse(JSON.stringify(altered));
    // All other primitive types
    if (depth === 0 && typeof base !== 'object') {
        return JSON.parse(JSON.stringify(base));
    }
    if (!(0, json_1.isJSONObject)(altered) || !(0, json_1.isJSONObject)(base))
        return {};
    const diff = Object.entries(altered).reduce((acc, [key, value]) => {
        if (
        // This key exists only in the altered version
        !(key in base) ||
            // This is the `styleAttr`, which is a special value that, when applying the
            // diff, will be concatenated (with a semicolon) since it's CSS.
            (depth === 0 && key === 'styleAttr')) {
            // Add to the override.
            acc[key] = value;
        }
        else if (
        // Exact same primitive value
        value === base[key] ||
            // Effectively the same value
            JSON.stringify(value) === JSON.stringify(base[key])) {
            // Do nothing. This empty case reduces the check count later.
        }
        else if (
        // Values of both are objects/arrays...
        typeof value === 'object' &&
            typeof base[key] === 'object') {
            // So recurse.
            acc[key] = (0, exports.getPropsDiff)(base[key] ?? null, value, depth + 1);
        }
        else {
            // Add to the override.
            acc[key] = value;
        }
        return acc;
    }, {});
    // Mark keys in the base that were manually removed in the altered version.
    Object.keys(base).forEach((key) => {
        if (!(key in altered) && key !== 'styleAttr') {
            diff[key] = exports.deletionSentinel;
        }
    });
    // With depth === 0, remove empty objects and if empty, return an empty object.
    return depth > 0 ? diff : (0, json_1.pruneEmptyObjects)(diff) ?? {};
};
exports.getPropsDiff = getPropsDiff;
/**
 * Applies a diff object to the override the props of a grouped module.
 * @param base JSONValue that can be overridden.
 * @param diff The override diff object.
 * @param isForFormDisplay For values that need operations other than replacement, the
 * form needs to apply the diff using different rules. At the time of creating this
 * function `styleAttr` was the only such value, but others could exist later.
 * @param depth How many levels of recursion this invocation is operating at. This is
 * likely exclusively relevant only for depth === 0.
 * @returns The overridden props for either displaying the module or populating the editing form.
 */
const applyPropsDiff = (base, diff, isForFormDisplay = false, depth = 0) => {
    if (!diff)
        return JSON.parse(JSON.stringify(base));
    if (base === exports.deletionSentinel || !base) {
        return JSON.parse(JSON.stringify(diff));
    }
    const altered = JSON.parse(JSON.stringify(base));
    if (!(0, json_1.isJSONObject)(base) || !(0, json_1.isJSONObject)(diff))
        return altered;
    // `altered` for the form should contain no trace of `base.styleAttr`, only
    // the diff's version of `base.styleAttr`, so delete it. If there's a value
    // present in the diff, it will be added in a later operation.
    if (depth === 0 && isForFormDisplay && !('styleAttr' in diff)) {
        delete altered.styleAttr;
    }
    const processed = Object.entries(diff).reduce((acc, [key, value]) => {
        const baseValue = base[key] ?? null;
        if (key === 'styleAttr' && !isForFormDisplay) {
            // When applying the diff for the attendee view, concatenated (with
            // a semicolon) since it's CSS.
            acc[key] = `${baseValue ?? ''};${value ?? ''}`;
        }
        else if (key === 'styleAttr' && isForFormDisplay) {
            // When applying the diff for the editing form, use the diff's version.
            // Casting [unlikely] falsey values to ensure the CSS stays valid.
            acc[key] = value || '';
        }
        else if (value === exports.deletionSentinel) {
            delete acc[key];
        }
        else if (value &&
            baseValue &&
            (0, json_1.isJSONObject)(value) &&
            (0, json_1.isJSONObject)(baseValue)) {
            acc[key] = (0, exports.applyPropsDiff)(baseValue, value, isForFormDisplay, depth + 1);
        }
        else {
            // All other cases, just set the value. Notably, including Arrays.
            acc[key] = value;
        }
        return acc;
    }, altered);
    return depth > 0 ? processed : JSON.parse(JSON.stringify(processed));
};
exports.applyPropsDiff = applyPropsDiff;
