"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pruneEmptyObjects = exports.parseStringify = exports.withoutKey = exports.isJSONValue = exports.isJSONObject = void 0;
/**
 * Determine if a given `JSONValue` is an object.
 * @param o to be checked.
 * @returns `true` if the given value is an object (not an array or primitive).
 */
function isJSONObject(o) {
    return typeof o === 'object' && o !== null && !Array.isArray(o);
}
exports.isJSONObject = isJSONObject;
/**
 * Determine if a given value can be used as a `JSONValue`. Checks recursively
 * if `o` is an object or an array.
 * @param o to be checked.
 * @returns `true` if the given value can be used as a `JSONValue`.
 */
function isJSONValue(o) {
    return (o === null ||
        typeof o === 'string' ||
        typeof o === 'number' ||
        typeof o === 'boolean' ||
        (Array.isArray(o) && o.every((v) => isJSONValue(v))) ||
        (typeof o === 'object' &&
            Object.entries(o).every(([, value]) => isJSONValue(value))));
}
exports.isJSONValue = isJSONValue;
/**
 * Returns a shallow copy of the given object, minus the given key.
 * @param obj A JS object that may have the given key.
 * @param key The key to be absent from the returned object.
 * @returns Shallow copy, mind the given key.
 */
const withoutKey = (obj, key) => {
    const next = Object.assign({}, obj);
    delete next[key];
    return next;
};
exports.withoutKey = withoutKey;
/**
 * As the name suggests, this function stringifies and then parses an object,
 * maintaining the type. The name is used to remind the developer about the
 * strengths and weaknesses of this function.
 * @param obj Any JSONValue.
 * @returns A deep copy of the given object.
 */
function parseStringify(obj) {
    return JSON.parse(JSON.stringify(obj));
}
exports.parseStringify = parseStringify;
/**
 * Takes a JSON Value and
 * * If it's a primitive, it returns that primitive.
 * * If it's an Object, it builds up a new object based on the supplied one,
 * minus all objects that contain zero leaf values in its tree and recurses
 * to process all children. Notably, if the initially-supplied object has zero
 * leaf values in its tree, this function will return `undefined`.
 * * Arrays are treated as primitives.
 * @example
 * pruneEmptyObjects({}) // undefined
 * @example
 * pruneEmptyObjects({a:{b:{c:{}}}}) // undefined
 * @example
 * pruneEmptyObjects({a:{b: [{},{},{c:{}}]}}) // {a:{b: [{},{},{c:{}}]}}
 * @example
 * pruneEmptyObjects(42) // 42
 * @example
 * pruneEmptyObjects({a:{b:"B", c:{}}}) // {a:{b:"B"}}
 */
function pruneEmptyObjects(obj) {
    if (obj === undefined) {
        return undefined;
    }
    else if (isJSONObject(obj)) {
        const newObj = {};
        // This for-loop was easier to read than a `reduce`.
        for (const key in obj) {
            const value = pruneEmptyObjects(obj[key]);
            if (value !== undefined) {
                newObj[key] = value;
            }
        }
        return Object.keys(newObj).length === 0 ? undefined : newObj;
    }
    else {
        return obj;
    }
}
exports.pruneEmptyObjects = pruneEmptyObjects;
